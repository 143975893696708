import React from 'react';
import ConversionForm from '../../Forms/ConversionForm';
import { ContentWrapper, ReferenceWrapper } from '../ConversionPanelStyles';

const ConversionFormOnlyComponent = ({
  formId,
  sfcid,
  subtitle,
  title,
  variant,
}) => (
  <ContentWrapper center>
    <ReferenceWrapper className="form-only">
      <ConversionForm
        formId={formId}
        title={title}
        subtitle={subtitle}
        sfcid={sfcid}
        variant={variant}
      />
    </ReferenceWrapper>
  </ContentWrapper>
);

export default ConversionFormOnlyComponent;
